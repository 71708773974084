import request from "../../utils/request";
let baseUrl = process.env.VUE_APP_BASE_URL2;

//获取摄像头设备 列表
export function querySmartMonitorEquipment(params) {
    return request({
      url: `${baseUrl}/op/monitorEquipment/querySmartMonitorEquipment/v261`,
      method: "POST",
      data: params
    });
  }
// 修改 设备名称
  export function editEquipmentName(params) {
    return request({
      url: `${baseUrl}/op/monitorEquipment/editEquipmentName/v261`,
      method: "POST",
      data: params
    });
  }

  // 获取  摄像头地址
  export function monitorEquipment(params) {
    return request({
      url: `${baseUrl}/op/monitorEquipment/getURL/v261`,
      method: "POST",
      data: params
    });
  }
